import React from 'react';
import { CommonForm } from 'Components';
import { components } from 'Common';

const { FormGroup } = components;

export class SystemSetingsForm extends CommonForm {
  modelName = 'settings';

  defaultItem = {
    registration_disabled: false
  }

  componentDidMount() {
    this.setState({
      item: this.props.settings,
      showCancelButton: false
    })
  }

  handleChangeRegistrationDisabled = ({ target }) => this.changeValue('registration_disabled', target.checked);

  get body() {
    const { item } = this.state;

    return (
      <div className="card card--size-md">
         <FormGroup label="Disable registration">
        <div className="checkbox-wrapper flex-actions__line-item" >
            <div className="checkbox-wrapper__check-style">
              <input type="checkbox"
                checked={!!item.registration_disabled}
                value={item.registration_disabled}
                onChange={this.handleChangeRegistrationDisabled}
                className="checkbox-wrapper__checkbox" 
                id='registration_disabled'
              />
              <label htmlFor='registration_disabled'></label>
              </div>
            </div>
        </FormGroup>
        {this.formFooter}
      </div>
    );
  }
}
